import { useEffect, useRef, useState } from "react";
import type { RefObject } from "react";

interface CommentFormDynamics {
  commentsSectionRef: RefObject<HTMLDivElement>;
  dynamicMarginBottom: number;
}

const useResizeObserver = (
  ref: RefObject<HTMLDivElement>,
  callback: (height: number) => void,
) => {
  useEffect(() => {
    const currentRef = ref.current;
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        callback(entry.contentRect.height);
      }
    });

    const findAndObserveReplyDiv = () => {
      const targetElement = currentRef?.querySelector(
        ".post__reply.reply-to-post",
      );
      if (targetElement) {
        observer.observe(targetElement);
      }
    };

    findAndObserveReplyDiv();

    const mutationObserver = new MutationObserver(findAndObserveReplyDiv);
    if (currentRef) {
      mutationObserver.observe(currentRef, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
      mutationObserver.disconnect();
    };
  }, [ref, callback]);
};

const useFocusObserver = (
  ref: RefObject<HTMLDivElement>,
  setFocused: (focused: boolean) => void,
) => {
  useEffect(() => {
    const currentRef = ref.current;
    const handleFocus = () => setFocused(true);
    const handleBlur = () => setFocused(false);

    const addListeners = () => {
      const replyDiv = currentRef?.querySelector(".post__reply.reply-to-post");
      if (replyDiv) {
        replyDiv.addEventListener("focusin", handleFocus);
        replyDiv.addEventListener("focusout", handleBlur);
      }
    };

    addListeners();

    const observer = new MutationObserver(addListeners);
    if (currentRef) {
      observer.observe(currentRef, { childList: true, subtree: true });
    }

    return () => {
      const replyDiv = currentRef?.querySelector(".post__reply.reply-to-post");
      if (replyDiv) {
        replyDiv.removeEventListener("focusin", handleFocus);
        replyDiv.removeEventListener("focusout", handleBlur);
      }
      observer.disconnect();
    };
  }, [ref, setFocused]);
};

export const usePostModalCommentFormDynamic = (): CommentFormDynamics => {
  const [isCommentFormFocused, setIsCommentFormFocused] = useState(false);
  const [commentFormHeight, setCommentFormHeight] = useState(0);
  const commentsSectionRef = useRef<HTMLDivElement>(null);

  useResizeObserver(commentsSectionRef, setCommentFormHeight);
  useFocusObserver(commentsSectionRef, setIsCommentFormFocused);

  const dynamicMarginBottom = isCommentFormFocused
    ? commentFormHeight + 32 + 20
    : commentFormHeight + 32;

  return {
    commentsSectionRef,
    dynamicMarginBottom,
  };
};
