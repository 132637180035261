import { useCallback } from "react";
import { pickBy } from "lodash";
import { useHistory } from "react-router-dom";
import { posts } from "@circle-react/helpers/urlHelpers/posts";
import type { Post } from "@circle-react/types";

interface NavigateToPostOptions {
  newPost: Post;
  prevLocation?: any;
  params?: Record<string, unknown> | string;
  hasNextPage?: boolean;
}

export const useNavigateToPost = () => {
  const history = useHistory();

  if (!history) {
    throw new Error("useNavigateToPost must be used within a Router component");
  }

  const navigateToPost = useCallback(
    ({
      newPost,
      prevLocation,
      params,
      hasNextPage,
    }: NavigateToPostOptions): void => {
      const state = pickBy(
        {
          prevLocation,
          params,
          hasNextPage,
        },
        value => value !== undefined,
      );

      history.push({
        pathname: posts.show({
          slug: newPost.slug,
          spaceSlug: newPost.space_slug,
        }),
        state,
      });
    },
    [history],
  );

  return { navigateToPost };
};
