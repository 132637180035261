import { t } from "i18n-js";
import { openUrl } from "@circle-react/helpers/urlHelpers/openUrl";
import type { Event } from "@circle-react/types";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { Button } from "@circle-react-uikit/Button";
import { ConfirmationDate } from "./ConfirmationDate";
import "./styles.scss";

interface ConfirmationModalProps {
  event: Event;
  show: boolean;
  onClose: () => void;
  shouldShowConfirmationDate?: boolean;
  isRecurring?: boolean;
  eventsCount?: number;
}

export const ConfirmationModal = ({
  event,
  show,
  onClose,
  shouldShowConfirmationDate = true,
  isRecurring = false,
  eventsCount,
}: ConfirmationModalProps) => {
  const { name, event_setting_attributes: eventData } = event || {};
  const {
    confirmation_message_title: title,
    confirmation_message_description: message,
    confirmation_message_button_title: buttonLabel,
    confirmation_message_button_link: buttonLink,
  } = eventData || {};

  const onButtonClick = () => {
    if (buttonLink) {
      openUrl(buttonLink);
    }
    onClose();
  };

  return (
    <Modal isOpen={!!show} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header className="flex w-full !justify-end">
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={onClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body>
          <div className="event-confirmation">
            <h2>{t("events.notifications.event_setting_attributes.clap")}</h2>
            <h3>
              {title ||
                t(
                  "events.notifications.event_setting_attributes.confirmation_message_title_placeholder",
                  { event_name: name },
                )}
            </h3>
            <p>
              {isRecurring
                ? message ||
                  t("events.rsvp.recurring_modal.confirmation_description", {
                    events: `${eventsCount} ${
                      eventsCount === 1
                        ? t("events.rsvp.recurring_modal.event")
                        : t("events.rsvp.recurring_modal.events")
                    }`,
                  })
                : message ||
                  t(
                    "events.notifications.event_setting_attributes.confirmation_message_description_placeholder",
                  )}
            </p>
            {shouldShowConfirmationDate && (
              <ConfirmationDate
                event={event}
                label={isRecurring ? t("add_all_events_to_calendar") : ""}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" full onClick={onButtonClick}>
            {buttonLabel ||
              t(
                "events.notifications.event_setting_attributes.confirmation_message_button_title_placeholder",
              )}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
