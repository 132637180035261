import classnames from "classnames";
import { t } from "i18n-js";
import { useLocation } from "react-router-dom";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { useIsOrCanBeCommunityMember } from "@circle-react/contexts";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { Icon } from "@circle-react-shared/Icon";
import { useNavigateToPost } from "../PostModal/hooks/useNavigateToPost";
import { usePostNavigationContext } from "../PostViewList/PostNavigationContext";
import { CommentCountLink } from "./CommentCountLink";
import { PostLikedByMemberStackV3 } from "./PostLikedByMemberStackV3";

interface CommentsFormProps {
  shouldOpenCommentPage: boolean;
  showCommentAndFocusCommentForm: () => void;
  toggleComment: () => void;
}

export const CommentsForm = ({
  shouldOpenCommentPage,
  showCommentAndFocusCommentForm,
  toggleComment,
}: CommentsFormProps) => {
  const isOrCanBeCommunityMember = useIsOrCanBeCommunityMember();
  const { shouldShowPostModal, isV3Enabled } = useIsV3();
  const location = useLocation();
  const { navigateToPost } = useNavigateToPost();
  const { params, hasNextPage } = usePostNavigationContext();

  const { post } = useCurrentPostHelpers();
  const {
    is_comments_closed: isCommentsClosed,
    is_comments_enabled: isCommentsEnabled,
    comments_count: commentsCount = 0,
    post_details_loaded,
  } = post;

  const isCommentsForm = isCommentsEnabled && !shouldOpenCommentPage;

  if (!isCommentsForm) {
    return (
      <div className="engagement__comments ml-auto mr-0 flex items-center gap-2">
        <PostLikedByMemberStackV3 shouldDisplayVerticalDivider={false} />
      </div>
    );
  }

  return (
    <>
      {!isCommentsClosed && isOrCanBeCommunityMember && (
        <button
          type="button"
          onClick={() => {
            shouldShowPostModal
              ? navigateToPost({
                  newPost: post,
                  prevLocation: location,
                  params,
                  hasNextPage,
                })
              : showCommentAndFocusCommentForm();
          }}
          aria-label={t("cap_comment_aria_label", {
            title: post?.name ?? t("this_post"),
          })}
          className={classnames(
            "action-comment text-default hover:!text-dark focus-visible:outline-secondary flex items-center text-sm font-medium transition-colors duration-150 ease-in-out focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
            isV3Enabled ? "px-1 py-0.5" : "px-2 py-1.5",
          )}
        >
          {isV3Enabled ? (
            <span className="action__icon">
              <Icon type="24-comment" />
            </span>
          ) : (
            <>
              <span className="action__icon mr-1.5 inline-flex">
                <Icon useWithFillCurrentColor type="message" />
              </span>
              <span className={classnames("action__label")}>
                {t("cap_comment")}
              </span>
            </>
          )}
        </button>
      )}
      <div
        className="engagement__comments ml-auto mr-0 flex items-center gap-2"
        data-testid="engagement-comments"
      >
        <PostLikedByMemberStackV3 />
        <CommentCountLink
          count={commentsCount}
          toggleComment={toggleComment}
          hasPostDetailsLoaded={post_details_loaded}
        />
      </div>
    </>
  );
};
